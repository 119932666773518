import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import StrataDocumentsModal from "./strata-documents-modal"
import TitleHeader from "../../components/title-header"

const StrataManagementDocuments = () => {
  const [currentForm, setCurrentForm] = useState("");
  const tenancyForm = "tenancyForm";
  const changeOfAddress = "changeOfAddress";
  const informationSheet = "informationSheet";
  const serviceRequest = "serviceRequest";
  const pdaAgreement = "pdaAgreement";

  const tenancyFormUrl = "https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/18";
  const changeOfAddressUrl = "https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/22";
  const informationSheetUrl = "https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/21";
  const serviceRequestUrl = "https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/23";
  const pdaAgreementUrl = "https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/20";

  return (
    <Layout>
      <SEO title="Strata Management | Korecki Real Estate Services" description="Korecki Real Estate Services is dedicated to providing strata corporations with the highest level of service and ensuring smooth building operations. With extensive experience in the Strata Management field, you can rely on Korecki Real Estate Services to provide sound advice and guidance." keywords="korecki,Real Estate,Strata Management services, Strata, Strata Management, Strata Property Management, Strata Management, Property Management, Client Property Account System"/>
      <div>
        <TitleHeader title={"Rental Management Documents"}/>
        {/*The following code is not from contentful*/}
        <Col md={12} className="blocktext" style={{ backgroundColor: 'rgba(250,250,250)'}}>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="middle justified-black" style={{fontSize: '20px'}}>Please click on and complete the relevant request form</p>
            </Row>
          </section>
          <Row style={{width: '60%', marginLeft: 'auto', marginRight: 'auto'}}>
            <Col md={6} className="half-section">
              <section className="section background">
                <div className="center-button">
                  <h1 className="text-uppercase font-weight-bold small-h1" style={{marginBottom: '5px'}}>Application Forms</h1>
                </div>
                <Row className="custom-sm-flex-order center">
                  <div className="center-button">
                    <button onClick={() => setCurrentForm(tenancyForm)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                      <StrataDocumentsModal
                        open={currentForm===tenancyForm}
                        openModal={setCurrentForm}
                        documentUrl={tenancyFormUrl}/>
                      Tenancy Application Form
                    </button>
                  </div>
                </Row>
              </section>
            </Col>
            <Col md={6} className="half-section">
              <section className="section background">
                <div className="center-button">
                  <h1 className="text-uppercase font-weight-bold small-h1" style={{marginBottom: '5px'}}>Contact Updates</h1>
                </div>
                <div className="center-button">
                  <button onClick={() => setCurrentForm(changeOfAddress)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                    <StrataDocumentsModal
                      open={currentForm===changeOfAddress}
                      openModal={setCurrentForm}
                      documentUrl={changeOfAddressUrl}/>
                    Change of Address & Contact Details
                  </button>
                </div>
                <div className="center-button">
                  <button onClick={() => setCurrentForm(informationSheet)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                    <StrataDocumentsModal
                      open={currentForm===informationSheet}
                      openModal={setCurrentForm}
                      documentUrl={informationSheetUrl}/>
                    Information Sheet & Emergency Contact List
                  </button>
                </div>
              </section>
            </Col>
            <Col md={6} className="half-section">
              <section className="section background">
                <Row className="custom-sm-flex-order center">
                  <div className="center-button">
                    <h1 className="text-uppercase font-weight-bold small-h1" style={{marginBottom: '5px'}}>Service Requests</h1>
                  </div>
                  <div className="center-button">
                    <button onClick={() => setCurrentForm(serviceRequest)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                      <StrataDocumentsModal
                        open={currentForm===serviceRequest}
                        openModal={setCurrentForm}
                        documentUrl={serviceRequestUrl}/>
                      Service Request Form
                    </button>
                  </div>
                </Row>
              </section>
            </Col>
            <Col md={6} className="half-section">
              <section className="section background">
                <Row className="custom-sm-flex-order center">
                  <div className="center-button">
                    <h1 className="text-uppercase font-weight-bold small-h1" style={{marginBottom: '5px'}}>Payments & Finances</h1>
                  </div>
                  <div className="center-button">
                    <button onClick={() => setCurrentForm(pdaAgreement)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                      <StrataDocumentsModal
                        open={currentForm===pdaAgreement}
                        openModal={setCurrentForm}
                        documentUrl={pdaAgreementUrl}/>
                      Pre-Authorized Debit Agreement
                    </button>
                  </div>
                </Row>
              </section>
            </Col>
          </Row>
        </Col>
      </div>
    </Layout>
  )
}
export default StrataManagementDocuments
